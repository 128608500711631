import { extend, override } from "flarum/extend";
import app from "flarum/app";
import HeaderSecondary from "flarum/components/HeaderSecondary";
import SettingsPage from "flarum/components/SettingsPage";
import LogInModal from "flarum/components/LogInModal";

app.initializers.add('flitsmeister-login', function () {

	override(LogInModal.prototype, 'oninit', redirectWhenLoginModalIsOpened);

	extend(HeaderSecondary.prototype, "items", items => {

        if (!items.has('logIn')) { return; }

		items.replace('logIn',
			<a href='https://mijn.flitsmeister.nl/redirect' className="Button Button--link">
				{app.translator.trans('core.forum.header.log_in_link')}
			</a>
        );
    });

	extend(SettingsPage.prototype, 'accountItems', removeProfileActions);
	extend(SettingsPage.prototype, 'settingsItems', checkRemoveAccountSection);

	function redirectWhenLoginModalIsOpened() {
		window.location.href = 'https://mijn.flitsmeister.nl/redirect';
		throw new Error('Stop execution');
    }

	function removeProfileActions(items) {
		items.remove('changeEmail');
		items.remove('changePassword');
	}

	function checkRemoveAccountSection(items) {

		if (items.has('account') && items.get('account').children.length === 0) {
			items.remove('account');
		}
	}
});
